import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoStructuredText } from '@/features/common/'
import { Deco } from '@/features/common/'
import { GatsbyImageFocused } from '@/features/common/'
import { DatoLink } from '@/features/dato-link'
import { absoluteFill, bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'section'> {
  heading?: NonNullable<
    Queries.WelcomePageQuery['datoCmsWelcomePage']
  >['missionHeading']
  body?: NonNullable<
    Queries.WelcomePageQuery['datoCmsWelcomePage']
  >['missionBody']
  link?: NonNullable<
    NonNullable<
      Queries.WelcomePageQuery['datoCmsWelcomePage']
    >['missionLink']
  >[number]
  backgroundImage?: NonNullable<
    Queries.WelcomePageQuery['datoCmsWelcomePage']
  >['missionBackground']
}

export const MissionPreviewSection = ({
  heading,
  body,
  link,
  backgroundImage,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      background-color: #000;
      border: 1.5rem solid #000;
      position: relative;
      box-sizing: border-box;
      ${mq().s} {
        border-width: 0.5rem;
      }
    `,
    image: css`
      box-sizing: border-box;
      border: 0.5rem solid #fff;
      ${absoluteFill}
    `,
    text: css`
      position: relative;
      padding: calc(0.5rem + var(--margin));
      color: #fff;
      display: grid;
      justify-items: center;
    `,
    heading: css`
      font-size: var(--fs-60);
      text-transform: uppercase;
      text-align: center;
      margin: 0.5em 0;
    `,
    body: css`
      margin: 1.5em 0;
      max-width: 60ch;
      font-size: var(--fs-21);
      font-weight: 300;
      ${mq().s} {
        font-size: var(--fs-18);
      }
    `,
    link: css`
      position: relative;
      font-size: var(--fs-21);
      border: 3px solid #fff;
      color: ${colors.gray10};
      padding: 3px;
      font-family: var(--ff-display-optical);
      margin-bottom: 2em;
      letter-spacing: 0.05em;
      > span {
        position: relative;
        display: block;
        padding: 0.75em 1em;
        z-index: 2;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        height: calc(100% - 12px);
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate(-50%, -50%);
      }
      &:before {
        position: absolute;
        width: calc(100% - 12px);
        background: #fff;
        transition: opacity 200ms ease;
      }
      &:after {
        width: 0%;
        background: #000;
        opacity: 0;
        transition:
          width 300ms ${bezier.easeOut},
          opacity 150ms ease 150ms;
      }
      @media (hover: hover) {
        &:hover {
          color: #fff;
          border-color: #000;
          &:before {
            opacity: 0;
          }
          &:after {
            opacity: 1;
            transition-delay: 0ms;
            width: calc(100% - 12px);
          }
        }
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <GatsbyImageFocused
        css={styles.image}
        image={backgroundImage?.gatsbyImageData}
        data={backgroundImage}
      />
      <div css={styles.text}>
        <h2 css={styles.heading}>{heading}</h2>
        <Deco fill="#fff" />
        <div css={styles.body}>
          <DatoStructuredText data={body} />
        </div>
        <DatoLink
          css={styles.link}
          data={link}
        />
      </div>
    </section>
  )
}

export const MissionPreviewSectionFragment = graphql`
  fragment MissionPreviewSectionImage on DatoCmsFileField {
    gatsbyImageData(
      width: 1920
      imgixParams: {
        q: 75
        sat: -100
        con: -60
        bri: -15
        exp: -15
        blendColor: "#d92636"
        blendMode: "overlay"
      }
    )
    ...ImageFocalData
  }
`
